import axios from "axios";
import { UserStatus } from "./types";
import { ApiResponse } from "../utils/types";

const api = axios.create({
  //   baseURL: "http://localhost:3000",
  // baseURL: "https://d27qnyddtuv1zw.cloudfront.net",
  baseURL: "https://api.wuko.app",
});

export async function fetchUserStatus(address: string) {
  const response = await api.get<ApiResponse<UserStatus | null>>(
    `/wukong/v1/wukong/status`,
    {
      params: {
        walletAddress: address,
      },
    }
  );
  return response.data.data;
}
// create user
export async function createUser(address: string, ref?: string) {
  const response = await api.post<ApiResponse<UserStatus | null>>(
    `/wukong/v1/wukong/connect-wallet`,
    {
      walletAddress: address,
      ref,
    }
  );
  return response.data.data;
}
// sub twitter
export async function subTwitter(address: string) {
  const response = await api.post<ApiResponse<UserStatus | null>>(
    `/wukong/v1/wukong/sub-twitter`,
    {
      walletAddress: address,
    }
  );
  return response.data.data;
}

// count white list
export async function getWhiteListCount() {
  const response = await api.get<ApiResponse<{ count: number }>>(
    `/wukong/v1/wukong/white-list-count`
  );
  return response.data.data;
}
