import { Flex, Text, Image } from "@chakra-ui/react";

import useScrollToTop from "../../hooks/useScrollToTop";
import { useEffect } from "react";
import Header from "../Header/Header";
import styles from "./Home.module.scss";
import CButton from "../UI/CButton/CButton";
import monkeyImage from "../../assets/images/common/monkey.png";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getWhiteListCount } from "../../api";

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  //get query params
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");
  console.log("ref", ref);

  const whiteListCountQuery = useQuery({
    queryKey: ["whiteListCount"],
    queryFn: async () => {
      return await getWhiteListCount();
    },
  });

  const whiteListCount = whiteListCountQuery.data?.count || 0;

  return (
    <>
      <Flex className={styles.monkeyContainer}>
        <Image src={monkeyImage} alt="monkey" className={styles.monkeyImage} />
      </Flex>
      <Flex flexDirection={"column"} alignItems={"center"} gap={2} zIndex={2}>
        <Header />
        <Text className={styles.userText} mt={12}>
          Players joined Season 1
        </Text>
        <Text className={styles.countText} mb={8}>
          {whiteListCount}
        </Text>
        <CButton
          name={"Let's Play"}
          colorScheme={"orange"}
          onClick={() => {
            navigate(`/welcome?ref=${ref}`);
          }}
        />
      </Flex>
    </>
  );
}
