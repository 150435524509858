import { Flex } from "@chakra-ui/react";
import styles from "./Card.module.scss";

export default function Card(props: {
  title: React.ReactNode;
  badge: React.ReactNode;
  desc: React.ReactNode;
  action: React.ReactNode;
}) {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.content}>
        <Flex className={styles.header}>
          <Flex className={styles.title}>{props.title}</Flex>
          <Flex className={styles.badge}>{props.badge}</Flex>
        </Flex>
        <Flex className={styles.desc}>{props.desc}</Flex>
      </Flex>
      <Flex className={styles.action}>{props.action}</Flex>
    </Flex>
  );
}
