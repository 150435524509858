import { Flex, Text, Image, useToast, Icon } from "@chakra-ui/react";
import Header from "../Header/Header";
import CButton from "../UI/CButton/CButton";
import styles from "./Congrats.module.scss";
import loadingImage from "../../assets/images/common/loading.png";
import Card from "../UI/Card/Card";
import Badge from "../UI/Badge/Badge";
import tgImage from "../../assets/images/common/tg.png";
import webImage from "../../assets/images/common/web.png";
import earnedImage from "../../assets/images/common/earned.png";
import { useLocation, useNavigate } from "react-router";
import { fetchUserStatus, createUser } from "../../api";
import { useWallet } from "../../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import copy from "copy-to-clipboard";
import { truncAddress } from "../../utils/utils";
import { IoIosExit, IoMdExit } from "react-icons/io";
import { useDisconnect } from "wagmi";

export default function Congrats() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");
  console.log("location", location, window.origin);
  const toast = useToast();
  const wallet = useWallet();
  const navigate = useNavigate();
  const userStatusQuery = useQuery({
    queryKey: ["userStatus", wallet.accountAddress],
    queryFn: async () => {
      const result = await fetchUserStatus(wallet.accountAddress as string);
      if (
        !(
          result?.isWhitelisted ||
          (result &&
            result.walletAddress &&
            result.twitterSub &&
            result.tgChannelSub)
        )
      ) {
        navigate(`/?ref=${ref}`);
      }
      return result;
    },
    enabled: !!wallet.accountAddress,
    refetchInterval: 3000,
  });
  const weblink = `${window.origin}/?ref=${userStatusQuery.data?.inviteCode}`;
  // const tgLink = `https://t.me/wukong_fate`;
  const tgLink = `https://t.me/WuKong_Community`;
  const { disconnect } = useDisconnect();
  if (!wallet.accountAddress) {
    navigate(`/?ref=${ref}`);
  }
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      gap={2}
      zIndex={2}
      mb={"100px"}
    >
      <Header />
      <Image
        src={loadingImage}
        alt="loadingImage"
        className={styles.loadingImage}
      />
      <Flex flexDirection={"column"} alignItems={"center"} mb={8}>
        <Text className={styles.title}>Congrats </Text>
        <Text className={styles.desc} mt={2}>
          You have successfully joined Season 1. Now you can earn 
          <Text as={"span"} color={"#F1B51B"}>
            even more WUKO.
          </Text>
        </Text>
      </Flex>

      <Flex className={styles.earnedContainer}>
        <Text fontWeight={500} fontSize={"18px"}>
          $WUKO earned
        </Text>
        <Text fontSize={"18px"}>{userStatusQuery.data?.points || 0}</Text>
      </Flex>

      <Flex className={styles.cardContainer}>
        <Flex className={styles.header}>
          <Flex className={styles.cardTitle}>
            <Text color={"#F1B51B"}>Invite Friends</Text>
          </Flex>
          <Flex className={styles.badge}>
            <Badge name={"+100 WUKO / Per friend"} />
          </Flex>
        </Flex>
        <Flex className={styles.desc}>
          <Text color={"#F1B51B"}>
            Share your link with friends, and the reward won’t keep you waiting!
          </Text>
        </Flex>
        <Flex className={styles.actions}>
          <Flex className={styles.action}>
            <Flex className={styles.actionTitle}>
              <Image src={webImage} alt="tg" className={styles.linkImage} />
              <Text className={styles.inviteLink}>{weblink}</Text>
            </Flex>
            <CButton
              name={"Copy Web link"}
              colorScheme={"yellow"}
              onClick={() => {
                copy(weblink);
                toast({
                  title: "Copied",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }}
            />
          </Flex>
          {/* <Flex className={styles.action}>
            <Flex className={styles.actionTitle}>
              <Image src={tgImage} alt="tg" className={styles.linkImage} />
              <Text className={styles.inviteLink}>{tgLink}</Text>
            </Flex>
            <CButton
              name={"Copy Telegram link"}
              colorScheme={"yellow"}
              onClick={() => {
                copy(tgLink);
                toast({
                  title: "Copied",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }}
            />
          </Flex> */}
        </Flex>
      </Flex>

      {/* <Card
        title={<Text color={"#F1B51B"}>New</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={
          <Text color={"#F1B51B"}>
            Add ... to your Telegram first name and receive an extra bonus at
            launch.
          </Text>
        }
        action={
          <CButton name={"Join"} colorScheme={"yellow"} onClick={() => {}} />
        }
      /> */}

      <Card
        title={<Text>Connect wallet</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={<Text>Connect your BEP-20 wallet.</Text>}
        action={
          <CButton
            name={
              <Flex
                alignItems={"center"}
                width={"80px"}
                justifyContent={"center"}
              >
                {truncAddress(wallet.accountAddress!)}
              </Flex>
            }
            colorScheme={"gray"}
            onClick={() => {}}
            onClickWhenComplete={() => disconnect()}
          />
        }
      />

      <Card
        title={<Text>Twitter</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={<Text>Follow Wukong on Twitter.</Text>}
        action={
          <CButton
            name={"Completed"}
            colorScheme={"gray"}
            onClick={() => {}}
            onClickWhenComplete={() => {
              window.open("https://x.com/WuKongonchain", "_blank");
            }}
          />
        }
      />

      <Card
        title={<Text>Telegram</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={<Text>Join Wukong in Telegram.</Text>}
        action={
          <CButton
            name={"Completed"}
            colorScheme={"gray"}
            onClick={() => {}}
            onClickWhenComplete={() => {
              window.open("https://t.me/WuKong_Community", "_blank");
            }}
          />
        }
      />
    </Flex>
  );
}
