import { Button, SpaceProps, Spinner, StyleProps } from "@chakra-ui/react";
import styles from "./CButton.module.scss";
import classnames from "classnames";
import React from "react";

type CButtonProps = {
  name: string | React.ReactNode;
  colorScheme: "orange" | "gray" | "yellow";
  onClick: () => void;
  className?: string;
  isloading?: boolean;
  isDisabled?: boolean;
  onClickWhenComplete?: () => void;
} & StyleProps;

// export default function CButton(props: CButtonProps) {
const CustomButton: React.FC<CButtonProps> = (props) => {
  const { name, isloading, colorScheme, ...otherProps } = props;
  const isDisabled = colorScheme === "gray" || isloading || props.isDisabled;
  let colorSchemeClass = "";
  if (colorScheme === "orange") {
    colorSchemeClass = styles.orange;
  } else if (colorScheme === "gray") {
    colorSchemeClass = styles.gray;
  } else if (colorScheme === "yellow") {
    colorSchemeClass = styles.yellow;
  }

  if (isDisabled || isloading || isDisabled) {
    colorSchemeClass = styles.gray;
  }

  return (
    <Button
      {...otherProps}
      onClick={isDisabled ? props.onClickWhenComplete : props.onClick}
      className={classnames(
        styles.container,
        colorSchemeClass,
        props.className
      )}
    >
      {isloading ? <Spinner /> : props.name}
    </Button>
  );
};

export default CustomButton;
