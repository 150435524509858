import { ChakraProvider, Flex, Image } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import styles from "./App.module.scss";

import useScrollToTop from "./hooks/useScrollToTop";
import { useEffect } from "react";

function App() {
  const scrollToTopx = useScrollToTop();
  const { search, pathname } = useLocation();
  console.log("pathname", pathname);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  return (
    <>
      <ChakraProvider>
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          className={styles.container}
        >
          <Outlet />
          <Flex className={styles.bgContainer}>
            <video autoPlay loop muted playsInline className="background-video">
              <source src={"mp4/particle.mp4"} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Flex>
        </Flex>
      </ChakraProvider>
    </>
  );
}

export default App;
