import { Flex, Image, Text } from "@chakra-ui/react";
import styles from "./Header.module.scss";
import MaxWidthContainer from "../MaxWidthContainer";
import logoImage from "../../assets/images/common/logo.png";
import logoTextImage from "../../assets/images/common/wukong.png";
import WalletButtonN from "../WalletButton/WalletButtonN";
import { useLocation, useNavigate } from "react-router";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  //get query params
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");
  return (
    <MaxWidthContainer>
      <Flex className={styles.container}>
        <Image
          src={logoImage}
          alt="logo"
          className={styles.image}
          cursor={"pointer"}
          onClick={() => navigate(`/?ref=${ref}`)}
        />
        <Image
          src={logoTextImage}
          alt="logo"
          className={styles.textImage}
          cursor={"pointer"}
          onClick={() => navigate(`/?ref=${ref}`)}
        />
        {/* <WalletButtonN /> */}
      </Flex>
    </MaxWidthContainer>
  );
}
