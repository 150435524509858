import { Flex, Text, Image, useDisclosure, Icon } from "@chakra-ui/react";
import Header from "../Header/Header";
import CButton from "../UI/CButton/CButton";
import styles from "./Welcome.module.scss";
import loadingImage from "../../assets/images/common/loading.png";
import Card from "../UI/Card/Card";
import Badge from "../UI/Badge/Badge";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { createUser, fetchUserStatus, subTwitter } from "../../api";
import { useWallet } from "../../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { asyncSleep, truncAddress } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router";
import { USER_TG_SUB_KEY } from "../../utils/consts";
import { IoMdExit } from "react-icons/io";
import { useDisconnect } from "wagmi";

export default function Welcome() {
  const openBotRef = React.useRef<HTMLAnchorElement>(null);
  const wallet = useWallet();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");
  const { disconnect } = useDisconnect();
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [isTwitterSubing, setIsTwitterSubing] = React.useState(false);
  const [isTelegramSubing, setIsTelegramSubing] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);

  const tgSubKey = `${USER_TG_SUB_KEY}_${wallet.accountAddress}`;
  const defaultIsTgSubClicked = !!localStorage.getItem(tgSubKey);
  const [isTgSubClicked, setIsTgSubClicked] = React.useState(
    defaultIsTgSubClicked
  );

  const userStatusQuery = useQuery({
    queryKey: ["userStatus", wallet.accountAddress],
    queryFn: async () => {
      const result = await fetchUserStatus(wallet.accountAddress as string);
      console.log("result", result);
      if (result?.isWhitelisted || (result && result.walletAddress && result.twitterSub && result.tgChannelSub)) {
        navigate(`/congrats?ref=${ref}`);
      }
      if (!result && !!wallet.accountAddress) {
        setIsConnecting(true);
        const newUser = await createUser(wallet.accountAddress, ref || "");
        // const newUser = await subTwitter(wallet.accountAddress);
        console.log("newUser", newUser);
        userStatusQuery.refetch();
      }
      setIsConnecting(false);
      return result;
    },
    enabled: !!wallet.accountAddress,
    refetchInterval: 3000,
  });

  const isUserCreated = !!userStatusQuery.data;
  const isWalletConnected = !!wallet.accountAddress;
  const isTwitterSubscribed = !!userStatusQuery.data?.twitterSub;
  const isTelegramSubscribed = !!userStatusQuery.data?.tgChannelSub;

  const { open } = useWeb3Modal();
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      gap={2}
      zIndex={2}
      className={styles.container}
    >
      <Header />
      <Image
        src={loadingImage}
        alt="loadingImage"
        className={styles.loadingImage}
      />
      <Flex flexDirection={"column"} alignItems={"center"} mb={8}>
        <Text className={styles.title}>Welcome </Text>
        <Text className={styles.desc} mt={2}>
          Season 1 has officially begun! Complete the tasks and start earning
          WUKO now!
        </Text>
      </Flex>

      <Card
        title={<Text>Connect wallet</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={<Text>Connect your BEP-20 wallet.</Text>}
        action={
          <CButton
            isloading={isConnecting}
            name={
              isWalletConnected ? (
                <Flex
                  alignItems={"center"}
                  width={"80px"}
                  justifyContent={"center"}
                >
                  {truncAddress(wallet.accountAddress!)}
                </Flex>
              ) : (
                "Connect"
              )
            }
            colorScheme={isWalletConnected ? "gray" : "orange"}
            onClick={() => open()}
            onClickWhenComplete={() => {
              disconnect();
              navigate(`/?ref=${ref}`);
            }}
          />
        }
      />

      <Card
        title={<Text color={"#F1B51B"}>Twitter</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={<Text color={"#F1B51B"}>Follow Wukong on Twitter.</Text>}
        action={
          <CButton
            isloading={isTwitterSubing}
            isDisabled={!isUserCreated || !isWalletConnected}
            name={isTwitterSubscribed ? "Completed" : "Follow"}
            colorScheme={isTwitterSubscribed ? "gray" : "orange"}
            onClick={async () => {
              setIsTwitterSubing(true);
              window.open("https://x.com/WuKongonchain", "_blank");
              await subTwitter(wallet.accountAddress!);
              await asyncSleep(5000);
              setIsTwitterSubing(false);
            }}
            onClickWhenComplete={() => {
              window.open("https://x.com/WuKongonchain", "_blank");
            }}
          />
        }
      />

      <Card
        title={<Text color={"#F1B51B"}>Telegram</Text>}
        badge={<Badge name={"+100 WUKO"} />}
        desc={<Text color={"#F1B51B"}>Join Wukong in Telegram.</Text>}
        action={
          <CButton
            isloading={isTelegramSubing}
            isDisabled={!isUserCreated}
            name={isTelegramSubscribed || isTgSubClicked ? "Completed" : "Join"}
            colorScheme={
              isTelegramSubscribed || isTgSubClicked ? "gray" : "orange"
            }
            onClick={async () => {
              setIsTelegramSubing(true);
              window.open("https://t.me/WuKong_Community", "_blank");
              await asyncSleep(5000);
              setIsTgSubClicked(true);
              localStorage.setItem(tgSubKey, "true");
              setIsTelegramSubing(false);
              // window.open("https://t.me/wukong_fate", "_blank");
            }}
            onClickWhenComplete={() => {
              window.open("https://t.me/WuKong_Community", "_blank");
            }}
          />
        }
      />

      <CButton
        className={styles.joinButton}
        name={"Verify & Join Season 1"}
        colorScheme={"orange"}
        isloading={isVerifying}
        isDisabled={
          !(wallet.isConnected && isTwitterSubscribed && isTgSubClicked)
        }
        onClick={async () => {
          setIsVerifying(true);
          console.log("verify and join whitelist");
          const result = await fetchUserStatus(wallet.accountAddress as string);
          const allDone = result?.isWhitelisted;
          if (allDone) {
            navigate(`/congrats?ref=${ref}`);
          } else {
            setTimeout(() => {
              openBotRef.current?.click();
            });
          }

          setIsVerifying(false);
        }}
      />

      <a
        href={`https://t.me/thewukong_bot?start=${wallet.accountAddress}`}
        target="_blank"
        id="thewukong_bot_link"
        rel="noreferrer"
        style={{ display: "none" }}
        ref={openBotRef}
      >
        Open in new window
      </a>
    </Flex>
  );
}
