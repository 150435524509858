import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

const useScrollToTop = () => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 10,
      smooth: "false",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
};

export default useScrollToTop;
