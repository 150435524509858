import React, {  } from 'react';
// import { useQueryClient } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import { Chain } from 'wagmi/chains';
import { Address } from 'viem';
import { useToast } from '@chakra-ui/react';

interface WalletContextType {
  accountAddress: Address | undefined;
  isConnected: boolean;
  currentChain: Chain | undefined;
}

const WalletContext = React.createContext<WalletContextType>({
  accountAddress: undefined,
  isConnected: false,
  currentChain: undefined
});

export const useWallet = () => React.useContext(WalletContext);



const useProviderWallet = () => {
  const toast = useToast();
  const { address: accountAddress, isConnected, chain: currentChain } = useAccount();

  return {
    accountAddress,
    isConnected,
    currentChain
  };
};

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useProviderWallet();
  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>;
};
export default WalletProvider;
