import React from 'react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { bsc } from 'viem/chains';

const queryClient = new QueryClient();

const projectId = process.env.REACT_APP_API_WALLET_CONNECT_PROJECT_ID!;

// 2. Create wagmiConfig
const metadata = {
  name: 'wukong',
  description: 'wukong',
  url: window.location.origin, // origin must match your domain & subdomain
  icons: ['']
};

const chains = [bsc] as const;

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableCoinbase: false
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
  themeMode: 'light',
  themeVariables: {
    '--w3m-z-index': 1401
  },
  enableEIP6963: false,
  featuredWalletIds: [
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'
  ]
});

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}

export function Web3ModalProvider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
